import React from "react";
import Menu from "../components/Menu";
import AnimationWrapper from "../components/AnimationWrapper";
import SEO from "../components/SEO";
import Image from "gatsby-image";

export default function projects({data}) {
  const logoSrc = data.allFile.nodes[9].childImageSharp.fluid

  return (
    <AnimationWrapper>
      <SEO />
      <div id="p-background">
        <Menu />
        <div className="subp-main flow">
          <h1 className="subp-animate">PÁLYÁZATAINK</h1>

          <h5 className="subp-animate">
            - NEA-E-17-0360 - "Hogy Én is tudjam! "
          </h5>

          <div className="content flow">
            <p className="paragraph-main subp-animate">
              A mindennapok során hiányként észleltük a fogyatékos személyek
              önállóságát megalapozó ismereteket, akik teljes bizonytalansággal
              állnak a mindennapi életvitelt meghatározó tevékenységek előtt.
              Ennek következtében a projekt segítségével ezen hiányosságokat
              pótoltuk. Célunk az volt, hogy a fogyatékos személyek a
              társadalomban könnyen, magabiztosan tudjanak élni.
            </p>

            <p className="subp-animate">
              A program az önálló és biztonságos életvitel kialakításához
              szükséges képességek kialakítását célozta meg.
            </p>

            <p className="subp-animate">
              A kiscsoportos foglalkozások lehetővé tették, hogy a résztvevők az
              általános ismereteket saját tapasztalataikon keresztül
              értelmezzék. Lehetőség nyílt arra, hogy az egyéni készségek és
              képességek figyelembe vételével egyénre szabottan is foglalkozzunk
              a programban résztvevő személyekkel, így maximálisan
              kihasználhatóak voltak az egyénekben rejlő lehetőségek
            </p>

            <p className="subp-animate">
              A projektbe 20 fő fogyatékos személy vett részt. A fogyatékos
              személyek önrendelkezési jogát tiszteletben tartva, ők igényeihez
              és kéréséhez igazodva ajánlottuk meg a projektben való részvétel
              lehetőségét.
            </p>

            <p className="subp-animate">
              A program 4 x 5 fős csoportban valósult meg, 22 alkalommal. A
              program megvalósulását a szakmai vezető mellett 6 fő önkéntes
              segítette.
            </p>

            <ul className="subp-animate">
              <h3>A program moduljai:</h3>
              <li>Személyi higiéné modul</li>
              <li>Környezet higiéné modul</li>
              <li>Háztartásgazdálkodás modul</li>
              <li>Élelmezés higiéné modul</li>
              <li>Ételkészítési ismeretek modul</li>
              <li>Munka, tűz és balesetvédelem modul</li>
              <li className="subp-animate">
                Elsősegély nyújtási ismeretek modul
              </li>
            </ul>

            <p id="emphasized" className="subp-animate">
              A foglalkozások interaktív jellege miatt, lehetőség nyílt a
              kommunikációs készségek fejlesztésére, a közösen végzett
              gyakorlati tevékenységek pedig lehetőséget nyújtottak a közösség
              építésére.
            </p>

            <p className="subp-animate">
              Hátrányos helyzetű csoport életviteli tanácsadás című program
              megvalósulásával olyan lehetőséget biztosítottunk, ismereteket
              adunk át, amely a legnagyobb mértékben közelít a többségi
              társadalom életéhez.
            </p>
            <p className="subp-animate">
              A projekt célja volt az önálló és biztonságos életvitel
              kialakítását segítő program megszervezése és lebonyolítása.
            </p>

            <ul className="subp-animate">
              <li className="subp-animate">
                NEA - TF-18-M-0583 – Működési támogatás
              </li>
              <li className="subp-animate">
                <span style={{textDecoration:"underline"}}>NEA- TF-19-Ö-V-0196 – Működés és szakmai támogatás:</span>
                <p style={{textAlign:"center", justifyContent:"center"}}>Támogató:</p>
                <Image style={{maxWidth: "60%", margin: "auto"}} fluid={logoSrc} alt="Bethlen Gábor Zrt és a Miniszterelnökség logója"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
}

export const query = graphql`
  {
    allFile(filter: { relativeDirectory: { ne: "svgs" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;